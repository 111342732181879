import { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import Linkify from 'react-linkify';

const MessageCardTitle = styled.div`
	color: #ffffff;
	font-size: 0.8em;
	height: 1em;
	display: flex;
	align-items: center;
`
const MessageCardContent = styled.div`
	white-space: pre-wrap; // render multi-lines text
	color: #ffffff;
	font-size: 0.8em;
`
const MessageCardWrapper = styled.div`
	display: flex;
`
const MessageCardUserWrapper = styled.div`
	display: flex;
	flex-direction: row; // column or row
	justify-content: space-around;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	padding-left: 1em;
`
const VerticalWrapper = styled.div`
	display: flex;
	flex-direction: column; // column or row
	justify-content: space-around;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	padding-left: 1em;
`
const MessageCardUserTitleWrapper = styled.div`
	display: flex;
	flex-direction: column; // column or row
	justify-content: space-around;
	margin-left: 0.5em;
	margin-right: 8px;
`
const MessageCardContentWrapper = styled.div`
`
const MessageCardImage = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
`
const FlexRowLink = styled(Link)`
	display: flex;
	flex-direction: row;
`
class MessageCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { content, user_id, user_title, user_image, rating, activityId } = this.props

		return (
			<FlexRowLink key={activityId} to={`/activity/${activityId}`}>
				<MessageCardWrapper>
					<MessageCardUserWrapper>
						<VerticalWrapper>
							<MessageCardImage src={user_image} />
							<MessageCardTitle >{user_title}</MessageCardTitle>
						</VerticalWrapper>
						<MessageCardUserTitleWrapper>
							<MessageCardTitle >{rating}/5</MessageCardTitle>
						</MessageCardUserTitleWrapper>
						<MessageCardUserTitleWrapper>
						</MessageCardUserTitleWrapper>
					</MessageCardUserWrapper>
					<MessageCardContentWrapper>
						<MessageCardContent ><div style={{ whiteSpace: "pre-wrap" }}>{content}</div></MessageCardContent>
					</MessageCardContentWrapper>
				</MessageCardWrapper >
			</FlexRowLink>
		)
	}
}

export default MessageCard
