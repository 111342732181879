import styled from 'styled-components'
import colors from '../utils/style/colors'
import ErrorIllustration from '../assets/404.jpg'

const ErrorWrapper = styled.div`
	margin: 30px;
	display: flex;
	flex-direction: column;
	background-color: ${colors.backgroundDark};
	align-items: center;
`

const ErrorTitle = styled.h1`
	color: '#ffffff';
	font-weight: 300;
`

const ErrorSubtitle = styled.h2`
	color: '#ffffff';
	font-weight: 300;
`

const Illustration = styled.img`
	max-width: 800px;
`

function Error() {

	return (
		<ErrorWrapper>
			<ErrorTitle>Erreur 404</ErrorTitle>
			<Illustration src={ErrorIllustration} />
			<ErrorSubtitle>
				Page non trouvée !
			</ErrorSubtitle>
		</ErrorWrapper>
	)
}

export default Error
