import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'


const CardLabel = styled.span`
	color: #ffffff;
	font-size: 0.8em;
	font-weight: normal;
`
const CardImage = styled.img`
	height: 9em;
	width: 9em;
	align-self: center;
	border-radius: 15%;
`
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0.6em;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	&:hover {cursor: pointer;}
	margin-bottom: 0.6em;
`
const CardTitle = styled.div`
	color: #ffffff;
	font-size: 1em;
	font-weight: normal;
	align-self: center;
	height: 1.2em;
	display: flex;
	align-items: center;
`

class UserCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { picture, birthday, title } = this.props

		return (
			<CardWrapper onClick={this.setFavorite}>
				<CardTitle>{title}</CardTitle>
				<CardImage src={picture} alt="img" />
				{birthday ? (
					<CardLabel>{birthday} ans</CardLabel>
				) : (<CardLabel>&nbsp;</CardLabel>)}
			</CardWrapper>
		)
	}
}

export default UserCard
