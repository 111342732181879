import { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import dateFormat from 'dateformat';
import Linkify from 'react-linkify';

const NotifCardTitle = styled.div`
	color: ${({ seen }) => (seen === "true" ? '#a39d9d' : '#ffffff')};
	font-size: ${({ seen }) => (seen === "true" ? '0.7em' : '0.8em')};
	font-weight: ${({ seen }) => (seen === "true" ? 'normal' : 'bold')};
	height: 1em;
	display: flex;
	align-items: center;
`
const NotifCardDateTitle = styled.div`
	color: #c6c2c2;
	font-size: ${({ seen }) => (seen === "true" ? '0.5em' : '0.6em')};
	font-weight: ${({ seen }) => (seen === "true" ? 'normal' : 'bold')};
	height: 1em;
	display: flex;
	align-items: center;
`
const NotifCardContent = styled.div`
	white-space: pre-wrap; // render multi-lines text
	color: ${({ seen }) => (seen === "true" ? '#a39d9d' : '#ffffff')};
	font-size: ${({ seen }) => (seen === "true" ? '0.8em' : '0.9em')};
	font-weight: ${({ seen }) => (seen === "true" ? 'normal' : 'bold')};
`
const NotifCardWrapper = styled.div`
	display: flex;
`
const NotifCardUserWrapper = styled.div`
	display: flex;
	flex-direction: row; // column or row
	justify-content: space-around;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	padding-left: 1em;
`
const NotifCardUserTitleWrapper = styled.div`
	display: flex;
	flex-direction: column; // column or row
	justify-content: space-around;
	margin-left: 0.5em;
	margin-right: 8px;
	
`
const NotifCardContentWrapper = styled.div`
`
const NotifCardImage = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
`

class NotifCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { content, created, user_id, user_title, user_image, seen } = this.props

		return (
			<NotifCardWrapper>
				<NotifCardUserWrapper>
					<NotifCardImage src={user_image} />
					<NotifCardUserTitleWrapper>
						<NotifCardTitle seen={seen} >{user_title}</NotifCardTitle>
						<NotifCardDateTitle seen={seen} >{dateFormat(new Date(created), "d mmm HH:MM")}</NotifCardDateTitle>
					</NotifCardUserTitleWrapper>
				</NotifCardUserWrapper>
				<NotifCardContentWrapper>
					<NotifCardContent seen={seen} ><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{content}</div></Linkify></NotifCardContent>
				</NotifCardContentWrapper>
			</NotifCardWrapper>
		)
	}
}

export default NotifCard
